<template>
  <v-container>
<!--    <h2 class="header__text fancy-font mt-6">ADMIN</h2>-->
    <v-container class="px-15" fluid>
      <v-row>
        <v-col class="px-0" lg="12" cols="12">
          <router-view
              @menuClick="handleClick"
              @notify="handleNotify"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar
        :color="notify.color"
        elevation="24"
        min-width="none"
        height="50"
        v-model="notify.visible">
      <div class="snack-text">
        <!--        <v-icon large class="pr-3">mdi-check-circle</v-icon>-->
        <v-icon large class="pr-3" v-if="notify.icon">{{  notify.icon }}</v-icon>
        {{ notify.text }}
      </div>
    </v-snackbar>
  </v-container>
</template>

<script>


export default {
  name: 'About',
  components: {
  },
  data() {
    return {
      name: '',
      notify: {
        visible: false,
        icon: null,
        text: null,
        color: null
      },
    }
  },
  methods: {
    handleClick(w) {
      if (this.$route.path !== '/admin/' + w) {
        this.$router.push('/admin/' + w);
      }
    },
    handleNotify(wht) {
      this.notify = wht
    }
  }
}
</script>
<style scoped>
 .snack-text {
   font-size: 1.6rem;
   text-align: center;
   display: block;
   margin-top: 3px;
   color: #ffffff;
 }
</style>
